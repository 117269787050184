var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        "supports-photo": "",
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        photo: _vm.photo
      },
      on: {
        submit: function($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        }
      }
    },
    [
      _c("div", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("BaseInput", {
              staticClass: "col-md-6",
              attrs: {
                label: _vm.$t("nickname"),
                placeholder: _vm.$t("enterNickname")
              },
              model: {
                value: _vm.item.nickname,
                callback: function($$v) {
                  _vm.$set(_vm.item, "nickname", $$v)
                },
                expression: "item.nickname"
              }
            }),
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", [_vm._v(_vm._s(_vm.$tc("category")))]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.category_id,
                      expression: "item.category_id"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.item,
                        "category_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.categories, function(category) {
                  return _c(
                    "option",
                    {
                      key: category.id + "category",
                      domProps: { value: category.id }
                    },
                    [_vm._v(" " + _vm._s(category.name) + " ")]
                  )
                }),
                0
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { slot: "middle" }, slot: "middle" },
        [
          _c("label", [_vm._v(_vm._s(_vm.$t("gallery")))]),
          _c(
            "div",
            { staticClass: "gallery" },
            [
              !_vm.gallery.length
                ? _c(
                    "div",
                    _vm._l(6, function(index) {
                      return _c("BaseIcon", {
                        key: index,
                        staticClass: "placeholderIcons",
                        attrs: {
                          "icons-group": "fas",
                          icon: "fa-image",
                          size: "xxl"
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "Draggable",
                {
                  staticClass: "galleryInner",
                  on: { change: _vm.updateGalleryModel },
                  model: {
                    value: _vm.gallery,
                    callback: function($$v) {
                      _vm.gallery = $$v
                    },
                    expression: "gallery"
                  }
                },
                _vm._l(_vm.gallery, function(galleryItem) {
                  return _c(
                    "div",
                    {
                      key: "gallery" + galleryItem.id,
                      staticClass: "galleryItem"
                    },
                    [
                      _c("BaseImage", {
                        attrs: { source: galleryItem, size: "thumb" }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "BaseButton",
                {
                  attrs: { type: "green", gradient: "" },
                  on: {
                    click: function($event) {
                      _vm.isGalleryModalOpened = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("updateGallery")) + " ")]
              )
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                show: _vm.isGalleryModalOpened,
                centered: true,
                "show-close": true
              },
              on: {
                "update:show": function($event) {
                  _vm.isGalleryModalOpened = $event
                }
              }
            },
            [
              _vm.isGalleryModalOpened
                ? _c("LibraryList", {
                    staticClass: "library",
                    attrs: {
                      limit: 40,
                      "select-items": "multiple",
                      "preselected-items": _vm.gallery,
                      "fixed-type": "photos",
                      "grid-item-size": "100"
                    },
                    on: { updateSelected: _vm.updateGallery }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "libraryButton text-right" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { type: "green", gradient: "" },
                      on: { click: _vm.confirmNewGallery }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" }, [
        _c(
          "div",
          [
            _c("BaseMultiSelect", {
              attrs: {
                label: _vm.$tc("brand", 2),
                items: _vm.brands,
                "pre-selected-items": _vm.item.brand
              },
              on: { updateSelected: _vm.updateSelectedBrands }
            })
          ],
          1
        )
      ]),
      _c("div", { attrs: { slot: "sidebar-bottom" }, slot: "sidebar-bottom" }, [
        _c("div", { staticClass: "metaIndexling form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("metaIndexing")))]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.item.seo_indexing,
                  expression: "item.seo_indexing"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.item,
                    "seo_indexing",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(" " + _vm._s(_vm.$t("indexing")) + " ")
              ]),
              _vm._l(_vm.indexing, function(index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: index } },
                  [_vm._v(" " + _vm._s(index) + " ")]
                )
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }